import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact1 from "../sections/contact/contact-1"

const Contact = () => {
  return (
    <Layout>
      <Seo title="Contact Us " />
      <Contact1 />
    </Layout>
  )
}

export default Contact
