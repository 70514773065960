import axios from "axios"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"

const Contact1 = () => {
  const [messageShow, setMessageShow] = useState(false)
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()
  const onSubmit = data => {
    axios
      .post(process.env.API_ENDPOINT + "alliance-and-partnership", data)
      .then(res => {
        if (res.status === 200) {
          console.log(res.data)
          setMessageShow(true)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
    <section id="contact-section1" className="pt-5">
      <div className="container h-100  mt-5 pt-5">
        <Row>
          <Col
            md="12"
            className="d-flex justify-content-center flex-column align-items-center mt-5"
          >
            <h1>Contact us</h1>
            <p>You are surrounded by potential allies</p>
           
          </Col>
          <Col md="12" className="contact-bottom">
            <h5>Support</h5>
            <h6>
              For technical support or questions about how to play SuperBetter,
              head to our{" "} Help Center.
            </h6>
          
            <h6 className="mb-2">
              There you can search for answers, or submit a request.
            </h6>

            <a
                href="https://superbetter.zendesk.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex justify-content-center align-items-center m-auto text-decoration-none mb-5 mt-4"
                style={{color: '#9EBA4F', width:140 , height:42 , border: '2px solid #9EBA4F' , borderRadius: 50 ,fontSize:18 , fontWeight:600 }}
              >
                Help Center
              </a>
           
           
          </Col>
          <Col md="12">
          <h5 className="text-center">Alliances & Partnerships</h5>
          </Col>
         
          <Col md="12" className="d-flex justify-content-center">
          
            <Row className="contact-form">
              <Col md="6">
                <h2>Start a Conversation</h2>
                <div className="email">
                  <p>
                    Interested in purchasing bulk subscriptions for your
                    organization? 
                  </p>

                  <p>Have an idea for an alliance? </p>

                  <p>We look forward to hearing from you.</p>
                </div>
              </Col>
              <Col md="6">
                {messageShow ? (
                  <div>
                    <h2>Message Sent Successfull.</h2>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-field">
                      <input
                        type="text"
                        placeholder="Name"
                        {...register("name", {
                          required: "Please enter the name",
                        })}
                      />
                      <span>{errors?.name?.message}</span>
                    </div>
                    <div className="input-field">
                      <input
                        type="email"
                        placeholder="Email"
                        {...register("email", {
                          required: "Please enter the email",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Please enter valid email",
                          },
                        })}
                      />
                      <span>{errors?.email?.message}</span>
                    </div>
                    <div className="input-field">
                      <textarea
                        placeholder="Messsage"
                        {...register("message", {
                          required: "Please enter the message",
                        })}
                      ></textarea>
                      <span>{errors?.message?.message}</span>
                    </div>
                    <div className="submit-btn">
                      <button className="btn submit">Submit</button>
                    </div>
                  </form>
                )}
              </Col>
            </Row>
          </Col>
          <Col md="12">
          <div className="address my-5 pt-5">
              <p>SuperBetter, LLC</p>
              <p>535 Duane Street, Floor 1.5 </p>
              <p>Glen Ellyn, IL 60137</p>
            </div>
          </Col>
         
        </Row>
      </div>
    </section>
  )
}

export default Contact1
